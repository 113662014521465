@use '../../../../../master.scss';

.chat-sidebar {
  top: 70px;
  transition: 0.4s all;
  position: fixed;
  height: calc(100% - 70px);
  width: 280px;
  left: 0;
  padding: 30px 0;
  background: master.$white;
  border-right: 1px solid master.$border-color;
  transform: translateX(-280px);
  overflow-y: auto;
  z-index: 99;

  .chats-listing {
    overflow: hidden;
    height: 100%;
    overflow-y: auto;

    button {
      background: transparent;
      border: none;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: left;
      /* Padding right is only 10px to allow the ellipsis to use a padding of 10px and keep look */
      padding: 0 10px 0 20px;
      height: 45px;
      font-size: 15px;
      transition: 0.3s all;

      &.chat-title {
        margin-top: 10px;
        padding: 12px 20px;
        border-top: 1px solid master.$offwhite;
        font-family: master.$font-arial-bold;

        &:first-child {
          border-top: none;
        }
      }
      .ellipsis-btn,
      .spinner-btn {
        padding: 10px;
      }

      input {
        padding: 3px 5px;
        background: transparent;
      }

      &.chat-item {
        cursor: pointer;

        .thumb {
          margin-right: 10px;
          width: 16px;
        }

        .title {
          width: calc(100% - 26px);
          @include master.text-wrap();

          @include master.media-mobile {
            width: calc(100% - 64px);
          }
        }

        .spinner-btn {
          color: master.$theme-color;
        }

        --action-box-width: 40px;
        .action-box {
          transition: width 0.7s;
          background: master.$offwhite-blurred;
          backdrop-filter: blur(1px);
          position: absolute;
          width: 0;
          overflow: hidden;
          pointer-events: none;
          right: 0;
          top: 0;
          height: 100%;
          color: master.$theme-color;
          @include master.flex-end();

          &::before {
            content: '';
            position: absolute;
            right: 0;
            width: var(--action-box-width);
            background: master.$offwhite;
            opacity: 0.3;
            height: 100%;

            @include master.media-mobile {
              content: none;
            }
          }

          @include master.media-mobile {
            width: var(--action-box-width);
            pointer-events: all;
            background: none;
            backdrop-filter: unset;
          }
        }

        &:hover {
          background: master.$offwhite;

          .action-box {
            width: var(--action-box-width);
            pointer-events: all;
          }
        }
        &.active {
          background: master.$offwhite;
        }
      }

      @include master.media-mobile {
        font-size: 13px;
        padding: 0 14px;
        height: 40px;
      }
    }
  }
  .empty-box {
    height: 100%;
    color: master.$lighter-grey;
    @include master.flex-center-column();
    .thumb {
      font-size: 80px;
    }
    h4 {
      color: master.$greyish-text;
      margin-top: 20px;
      font-style: italic;
    }
  }

  @include master.media-mobile {
    padding: 20px 0;
  }

  @include master.media-desktop {
    width: 240px;
    transform: translateX(-240px);
  }

  @include master.media-tablet {
    transform: translateX(0);
  }

  &.active {
    transform: translateX(0);

    @include master.media-tablet {
      transform: translateX(-240px);
    }
  }
}


.chat-sidebar-menu-dropdown{
  .ant-dropdown-menu-item-icon {
    height: 1em;
  }
  button {
    display: block;
    cursor: pointer;
    border: none;
    background: none;  
  }
  
  .ant-dropdown-menu-item-danger:hover {
    button {
      color: #fff;
    }
  }
}