@use "../../../../../master.scss";

.chat-layout {
  .avatar-chat-box {
    background: master.$black;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    margin-left: 0;
    height: 100%;

    .top-header {
      background: rgba(0, 0, 0, 0.4);
      border: none;

      .left-box {
        min-width: auto;
        margin-right: 30px;
        @media (min-height: 1200px) {
          position: absolute;
          left: 2.5vh;
          .logo-box img {
            width: 6.5vh;
          }
        }
      }

      .project-title {
        color: master.$white;
        @media (min-height: 1200px) {
          font-size: 2vh;
        }
      }
      @media (min-height: 1200px) {
        height: 6.5vh;
        padding: 0 2.5vh;
        justify-content: center;
      }
    }
    .permission-error {
      height: 100%;
      @include master.flex-center();
      color: master.$theme-color;
      font-size: 28px;
      font-family: master.$font-arial-bold;
      @media (min-height: 1200px) {
        font-size: 2.5vh;
      }
    }
    .loader-wrapper {
      position: absolute;
      background: master.$white !important;
      width: 100%;
      height: 100% !important;
    }
    .avatar-preview {
      position: absolute;
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .suggestions-toggle {
      position: absolute;
      top: 90px;
      right: 20px;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      box-shadow: 0px 4px 4px 0px #00000040;
      background: master.$theme-color-primary;
      overflow: hidden;
      border: 2px solid master.$white;
      padding-bottom: 2px;
      @include master.flex-center();

      @include master.media-smalldevice {
        width: 35px;
        height: 35px;
        font-size: 15px;
        img {
          width: 20px;
        }
      }
      @media (min-height: 1200px) {
        top: 8.5vh;
        right: 2.5vh;
        width: 5.3vh;
        height: 5.3vh;
        border: 0.25vh solid master.$white;
        padding-bottom: 0.25vh;
        img {
          width: 2.7vh;
        }
      }
    }
    .top-box {
      position: absolute;
      bottom: 150px;
      width: 100%;
      left: 0;
      padding: 0 30px;
      .subtitles-box {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: auto;
        max-height: 400px;
        width: 85%;
        margin: 0 auto 50px;
        p {
          padding: 5px;
          background: master.$black;
          color: master.$white;
          text-align: center;
          display: inline;
          font-size: 30px;
          @media (min-height: 1200px) {
            padding: 0.5vh 0.3vh;
            font-size: 2.7vh;
          }
          @include master.media-smalldevice {
            font-size: 16px;
          }
        }
        @media (min-height: 1200px) {
          max-height: 45vh;
          margin: 0 auto 2.3vh;
        }
        @include master.media-smalldevice {
          width: 100%;
          margin: 0 auto 20px;
        }
      }
      .suggestions-box {
        h2 {
          font-size: 30px;
          color: master.$white;
          font-family: master.$font-arial-bold;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          @include master.flex-start();
          gap: 10px;
          img {
            bottom: 2px;
            width: 28px;
            @media (min-height: 1200px) {
              bottom: 0.15vh;
              width: 2.8vh;
            }
            @include master.media-smalldevice {
              width: 20px;
            }
          }
          @media (min-height: 1200px) {
            font-size: 2.7vh;
            text-shadow: 0 0 1.2vh rgba(0, 0, 0, 0.5);
            gap: 1.2vh;
          }
          @include master.media-smalldevice {
            font-size: 22px;
          }
        }
        .suggestions-list {
          margin-top: 25px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          button {
            margin-top: 5px;
            border: none;
            padding: 10px 25px;
            border-radius: 50px;
            font-size: 16px;
            background: master.$light-black;
            color: master.$white;
            text-align: left;
            cursor: pointer;
            transition: 0.3s all;
            &:hover {
              background: master.$black;
            }
            @include master.media-smalldevice {
              padding: 5px 10px;
              font-size: 12px;
              margin-top: 3px;
            }
            @media (min-height: 1200px) {
              margin-top: 0.5vh;
              padding: 1.2vh 2.4vh;
              border-radius: 5.3vh;
              font-size: 1.5vh;
            }
          }
          @media (min-height: 1200px) {
            margin-top: 3vh;
            gap: 1.2vh;
          }
          @include master.media-smalldevice {
            margin-top: 15px;
          }
        }
      }
      @media (min-height: 1200px) {
        bottom: 14vh;
        padding: 0 2.5vh;
      }
      @include master.media-smalldevice {
        padding: 0 10px;
        bottom: 110px;
      }
    }
    .bottom-box {
      width: 100%;
      position: absolute;
      bottom: 50px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // flex-direction: column;

      .input-box {
        position: relative;
        width: 55px;
        border-radius: 50px;
        overflow: hidden;
        @include master.flex-start();
        button {
          position: absolute;
          min-width: 55px;
          padding: 0;
          height: 55px;
          border-radius: 50%;
          z-index: 9;
          border: 1px solid master.$border-color;
          background: master.$yellowish-white;
          font-size: 24px;
          cursor: pointer;
          transition: 0.3s all;
          @include master.flex-center();
          svg {
            margin: 0;
          }
          &:hover {
            background: master.$grey;
          }
          &:disabled {
            opacity: 0.6;
          }
          &.toggle-button {
            z-index: 999;
          }
          &.search-button {
            background: master.$theme-color-primary;
            border-color: master.$theme-color-primary;
            left: auto;
            right: 0;
          }
          @media (min-height: 1200px) {
            min-width: 5.3vh;
            height: 5.3vh;
            border: 0.12vh solid master.$border-color;
            font-size: 2.4vh;
          }
          @include master.media-smalldevice {
            min-width: 35px;
            height: 35px;
            font-size: 15px;
          }
        }
        input {
          font-size: 17px;
          width: 100%;
          height: 55px;
          border-radius: 50px;
          padding: 0 70px 2px;

          &::placeholder {
            color: master.$dark-grey;
          }
          @media (min-height: 1200px) {
            font-size: 2vh;
            height: 5.3vh;
            border-radius: 5.3vh;
            padding: 0 8vh 0.25vh;
          }
          @include master.media-smalldevice {
            height: 35px;
            padding: 0 45px 2px;
            font-size: 14px;
          }
        }
        &.active {
          width: 100%;
        }
        @include master.media-smalldevice {
          width: 35px;
        }
        @media (min-height: 1200px) {
          width: 5.3vh;
          border-radius: 5.3vh;
        }
      }
      .action-box {
        @include master.flex-center();
        gap: 20px;
        button {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          border: 1px solid master.$border-color;
          background: master.$yellowish-white;
          font-size: 24px;
          cursor: pointer;
          transition: 0.3s all;
          &:hover {
            background: master.$grey;
          }
          @media (min-height: 1200px) {
            width: 5.3vh;
            height: 5.3vh;
            border: 0.12vh solid master.$border-color;
            font-size: 2.4vh;
          }
          @include master.media-smalldevice {
            width: 35px;
            height: 35px;
            font-size: 15px;
          }
        }
        @media (min-height: 1200px) {
          gap: 1.8vh;
        }
        @include master.media-smalldevice {
          gap: 10px;
        }
      }

      .listening-box {
        border: 1px solid master.$border-color;
        padding: 0 25px;
        height: 50px;
        border-radius: 50px;
        font-size: 20px;
        display: flex;
        align-items: center;
        transform: translateY(140px);
        transition: 1s all;
        background: master.$white;
        width: 230px;
        .frequency {
          margin-left: 30px;
          height: 60px;
          display: flex;
          align-items: center;
          gap: 10px;
          .bar {
            border-radius: 50px;
            width: 15px;
            transform-origin: center bottom;
            transition: height 0.2s ease;
            background: master.$audio-loader;
            @media (min-height: 1200px) {
              border-radius: 5.3vh;
              width: 1.8vh;
            }
            @include master.media-smalldevice {
              width: 10px;
            }
          }
          @media (min-height: 1200px) {
            margin-left: 2.5vh;
            height: 7vh;
            gap: 1.2vh;
          }
          @include master.media-smalldevice {
            height: 35px;
            margin-left: 25px;
            gap: 7px;
          }
        }
        &.active {
          display: flex;
          transform: translateY(0);
        }
        @media (min-height: 1200px) {
          border: 0.12vh solid master.$border-color;
          padding: 0 3vh;
          height: 5.5vh;
          border-radius: 5.3vh;
          font-size: 2.4vh;
          transform: translateY(15.3vh);
          width: 28vh;
        }
        @include master.media-smalldevice {
          width: 160px;
          height: 35px;
          padding: 0 15px;
          font-size: 14px;
        }
      }
      .tagline {
        background: master.$white;
        padding: 10px;
        width: 100%;
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
        display: none;
        @include master.media-tablet {
          font-size: 13px;
          margin-top: 8px;
        }

        @include master.media-mobile {
          line-height: 18px;
        }
      }
      @include master.media-smalldevice {
        padding: 0 10px;
      }
      @media (min-height: 1200px) {
        bottom: 5.3vh;
        padding: 0 2.5vh;
      }
    }
    .reset-session-button {
      cursor: pointer;
      color: master.$white;
      position: fixed;
      top: 15px;
      right: 30px;
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: transparent;
      border: 1px solid master.$grey;
      border-radius: 50px;
      padding: 10px 25px;
      font-size: 15px;
      cursor: pointer;
      transition: all 0.3s;
      z-index: 1000;

      &:hover {
        border-color: master.$dark-grey;
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      svg {
        font-size: 14px;
        @media (min-height: 1200px) {
          font-size: 1vh;
        }
      }
      @media (min-height: 1200px) {
        top: 1.6vh;
        right: 2vh;
        gap: 0.4vh;
        border: 0.1vh solid master.$grey;
        border-radius: 5.3vh;
        padding: 0.6vh 1.8vh;
        font-size: 1.3vh;
      }
    }
  }
}
